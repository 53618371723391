import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./auth";
import { listPersonnals } from "@defense-station/api-service";
import { useDisclosure } from "@chakra-ui/react";
import { showErrorToast } from "../services/toast-service";

const INITIAL_FILTER = {
  search: "",
};

const PersonnelContext = createContext();

const PersonnelProvider = ({ children, context }) => {
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const [graphData, setGraphData] = useState({
    nodes: [],
    edges: [],
  });
  const [isLoading, setLoading] = useState(true);
  const [selectedPersonnel, setSelectedPersonnel] = useState();
  const {
    isOpen: isPersonnelModalOpen,
    onOpen: onPersonnelModalOpen,
    onClose: onPersonnelModalClose,
  } = useDisclosure();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [initialized, setInitialized] = useState(false);
  const [isLoadingNext, setLoadingNext] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [filter, setFilter] = useState(INITIAL_FILTER);
  const [isRefreshing, setRefreshing] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const fetchData = async (
    state = {
      reset: false,
    }
  ) => {
    try {
      let args = {
        username: user?.username,
        account_id: user?.account_id,
        include_relations: true,
        page_size: pageSize,
        page_number: state.reset ? 1 : page,
      };
      if (filter) {
        if (filter.search) {
          args = { ...args, search: filter.search };
        }
      }
      const result = await listPersonnals(args);
      const obj = result?.data?.policy_manager_v1_ListPersonnels;
      let { personnels, relations } = obj
        ? obj
        : { personnels: [], relations: [] };
      if (!personnels?.length) {
        setIsLast(true);
        return;
      }
      personnels = personnels?.length ? [...personnels].reverse() : [];
      let graphObject = {};
      const nodes = personnels?.map((p) => ({
        id: p.email,
        label: p.first_name + p.last_name,
        data: p,
      }));
      const edges = relations?.map((r) => ({
        id: r?.start_email + "-" + r?.end_email,
        source: r?.start_email,
        target: r?.end_email,
      }));
      if (page == pageSize || personnels?.length < pageSize) {
        setIsLast(true);
      }
      // setPage(result?.data?.policy_manager_v1_ListPersonnels.last_cursor);
      setData(
        personnels?.length
          ? state?.reset
            ? personnels
            : [...data, ...personnels]
          : state?.loadingNext
          ? data
          : []
      );
      setGraphData({
        nodes: nodes?.length
          ? state?.reset
            ? nodes
            : [...graphData.nodes, ...nodes]
          : state?.loadingNext
          ? graphData.nodes
          : [],
        edges: edges?.length
          ? state?.reset
            ? edges
            : [...graphData.edges, ...edges]
          : state?.loadingNext
          ? graphData.edges
          : [],
      });
    } catch (e) {
      console.log(e);
      showErrorToast(e?.message);
    } finally {
      setInitialized(true);
      setLoadingNext(false);
      setLoading(false);
      setIsSearching(false);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    if (user && initialized) {
      setIsSearching(true);
      setIsLast(false);
      setData([]);
      setPage();
      setGraphData({
        nodes: [],
        edges: [],
      });
      fetchData({
        reset: true,
      });
    }
  }, [user, filter]);

  useEffect(() => {
    if (initialized) {
      setLoadingNext(true);
      fetchData();
    }
  }, [page, pageSize]);

  const updateGraphData = (newData) => {
    const nodes = newData?.map((p) => ({
      id: p.email,
      label: p.first_name + p.last_name,
      data: p,
    }));
    const edges = newData?.map((r) => ({
      id: r?.start_email + "-" + r?.end_email,
      source: r?.start_email,
      target: r?.end_email,
    }));
    setGraphData({
      nodes,
      edges,
    });
  };

  const addPersonnel = (personnel) => {
    console.log({ personnel, data });
    const newPersonnels = [...data, personnel];
    setData(newPersonnels);
    updateGraphData(newPersonnels);
  };

  const removePersonnel = (personnel) => {
    const newPersonnels = data?.filter(
      (c) => c.personnel_id != personnel.personnel_id
    );
    setData(newPersonnels);
    updateGraphData(newPersonnels);
  };

  const updatePersonnel = (personnel) => {
    const newPersonnels = data?.map((c) => {
      if (c.personnel_id == personnel.personnel_id) {
        return personnel;
      } else {
        return c;
      }
    });
    setData(newPersonnels);
    updateGraphData(newPersonnels);
  };

  const resetState = () => {
    setIsLast(false);
    setData([]);
    setPage();
    setGraphData({
      nodes: [],
      edges: [],
    });
  };

  const onRefresh = async () => {
    setRefreshing(true);
    setIsSearching(true);
    resetState();
    await fetchData({
      reset: true,
    });
    setRefreshing(false);
  };

  const fetchNext = () => {
    if (initialized) {
      setLoadingNext(true);
      setPage(page + 1);
    }
  };

  const updateFilter = (f) => {
    setFilter(f);
  };

  const resetFilter = () => setFilter(INITIAL_FILTER);

  const value = {
    isPersonnelModalOpen,
    onPersonnelModalOpen,
    onPersonnelModalClose,
    data,
    setData,
    addPersonnel,
    removePersonnel,
    updatePersonnel,
    selectedPersonnel,
    setSelectedPersonnel,
    isLoadingNext,
    initialized,
    setInitialized,
    isLast,
    setIsLast,
    isLoading,
    pageSize,
    setPageSize,
    page,
    setPage,
    resetState,
    isRefreshing,
    onRefresh,
    fetchNext,
    fetchData,
    updateFilter,
    filter,
    resetFilter,
    isSearching,
    setIsSearching,
    graphData,
    setGraphData,
  };

  return (
    <PersonnelContext.Provider value={value}>
      {children}
    </PersonnelContext.Provider>
  );
};

export const usePersonnel = () => useContext(PersonnelContext);

export default PersonnelProvider;
