import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./auth";
import { listRoles } from "@defense-station/api-service";
import { useDisclosure } from "@chakra-ui/react";
import { showErrorToast } from "../services/toast-service";

const INITIAL_FILTER = {
  search: "",
  ds_managed: false,
};

const RoleContext = createContext();

const RoleProvider = ({ children, context }) => {
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const [graphData, setGraphData] = useState({
    nodes: [],
    edges: [],
  });
  const [isLoading, setLoading] = useState(true);
  const [selectedRole, setSelectedRole] = useState();
  const {
    isOpen: isRoleModalOpen,
    onOpen: onRoleModalOpen,
    onClose: onRoleModalClose,
  } = useDisclosure();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [initialized, setInitialized] = useState(false);
  const [isLoadingNext, setLoadingNext] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [filter, setFilter] = useState(INITIAL_FILTER);
  const [isRefreshing, setRefreshing] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const fetchData = async (
    states = {
      resetState: false,
    }
  ) => {
    console.log({ page, data }, "Fetching...");
    let args = {
      include_relations: process.env.ENV == "dev" ? true : false,
      account_id: user.account_id,
      username: user.username,
      page_number: states?.resetState ? 1 : page,
      page_size: pageSize,
    };
    if (filter) {
      if (filter.search) {
        args = { ...args, role_name: filter.search };
      }
    }
    const result = listRoles(args)
      .then((response) => {
        let { roles, relations } = response?.data?.policy_manager_v1_ListRoles;
        roles = roles?.length ? [...roles].reverse() : [];
        console.log("Roles", roles.length);
        if (!roles?.length) {
          console.log("Last true");
          setIsLast(true);
          return;
        }
        const rolesArray = roles?.map((role) => ({
          id: role.role_id ? role.role_id : randomString(),
          data: {
            id: role.role_id,
            ...role,
          },
        }));
        const relationsArray = relations?.map((relation) => ({
          id: relation?.start_role_id + "-" + relation?.end_role_id,
          data: {
            ...relation,
          },
          source: relation?.start_role_id,
          target: relation?.end_role_id,
        }));
        if (roles?.length < pageSize) {
          setIsLast(true);
        }
        // setPage(response?.data?.policy_manager_v1_ListRoles.last_cursor);
        setData(
          roles?.length
            ? states?.resetState
              ? roles
              : [...data, ...roles]
            : states?.loadingNext
            ? data
            : []
        );
        setGraphData({
          nodes: rolesArray?.length
            ? states?.resetState
              ? rolesArray
              : [...graphData?.nodes, ...rolesArray]
            : states?.loadingNext
            ? graphData?.nodes
            : [],
          edges: relationsArray?.length
            ? states?.resetState
              ? relationsArray
              : [...graphData?.edges, ...relationsArray]
            : states?.loadingNext
            ? graphData?.edges
            : [],
        });
      })
      .catch((e) => {
        console.log(e);
        showErrorToast(e?.message);
      })
      .finally(() => {
        setInitialized(true);
        setLoading(false);
        setLoadingNext(false);
        setIsSearching(false);
        setRefreshing(false);
      });
    return result;
  };

  useEffect(() => {
    if (user && initialized) {
      console.log("Loading");
      setIsSearching(true);
      fetchData({
        resetState: true,
      });
    }
  }, [user, filter]);

  useEffect(() => {
    if (initialized) {
      setLoadingNext(true);
      fetchData(page == 1 ? { resetState: true } : {});
    }
  }, [page, pageSize]);

  const onRoleRelationDelete = (edge) => {
    const edges = graphData?.edges?.filter((e) => e.id != edge.id);
    setGraphData((oldData) => {
      return {
        ...oldData,
        edges: edges,
      };
    });
  };

  const onRoleRelationCreated = (edge) => {
    const edges = [...graphData?.edges, edge];
    setGraphData((oldData) => {
      return {
        ...oldData,
        edges: edges,
      };
    });
  };

  const addRole = (role) => {
    console.log({ role, data });
    const newRoles = [...data, role];
    setData(newRoles);
  };

  const removeRole = (role) => {
    const newRoles = data?.filter((c) => c.role_id != role.role_id);
    setData(newRoles);
  };

  const updateRole = (role) => {
    const newRoles = data?.map((c) => {
      if (c.role_id == role.role_id) {
        return role;
      } else {
        return c;
      }
    });
    setData(newRoles);
  };

  const resetState = () => {
    setData([]);
    setIsLast(false);
    setPage(1);
  };

  const onRefresh = () => {
    setRefreshing(true);
    resetState();
    if (page == 1) {
      fetchData({
        resetState: true,
      });
    }
  };

  const fetchNext = () => {
    if (initialized) {
      setLoadingNext(true);
      setPage(page + 1);
    }
  };

  const updateFilter = (f) => {
    setFilter(f);
  };

  const resetFilter = () => setFilter(INITIAL_FILTER);

  const value = {
    isRoleModalOpen,
    onRoleModalOpen,
    onRoleModalClose,
    data,
    setData,
    graphData,
    setGraphData,
    addRole,
    removeRole,
    updateRole,
    selectedRole,
    setSelectedRole,
    isLoadingNext,
    initialized,
    setInitialized,
    isLast,
    setIsLast,
    isLoading,
    pageSize,
    setPageSize,
    page,
    setPage,
    resetState,
    isRefreshing,
    onRefresh,
    fetchNext,
    fetchData,
    updateFilter,
    filter,
    resetFilter,
    isSearching,
  };

  return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
};

export const useRole = () => useContext(RoleContext);

export default RoleProvider;
