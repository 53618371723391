import { useDisclosure } from "@chakra-ui/react";
import { createContext, useContext, useEffect, useState } from "react";

const MermaidContext = createContext({});

export const MermaidProvider = ({ children }) => {
  // We can have our existing Auth logic here, just making sure that we have a single QueryClient.
  const [mermaidCode, setMermaidCode] = useState();
  const { isOpen, onClose, onOpen } = useDisclosure();


  // Any user details we want can go here
  const value = {
    mermaidCode,
    setMermaidCode,
    isOpen, onClose, onOpen
  };

  return <MermaidContext.Provider value={value}>{children}</MermaidContext.Provider>;
};
export const useMermaid = () => useContext(MermaidContext);