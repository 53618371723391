import { Button, useBreakpointValue } from "@chakra-ui/react";
import React, { forwardRef } from "react";

export default forwardRef(({ children, ...rest }, ref) => {
  const buttonSize = useBreakpointValue({
    base: "sm",
    xl: "sm",
    "2xl": "sm",
  });
  return (
    <Button ref={ref} size={buttonSize} {...rest}>
      {children}
    </Button>
  );
})
