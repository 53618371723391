import { Form, Formik } from "formik";
import React, { useMemo, useState } from "react";
import TextField from "../fields/TextField";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  DrawerHeader,
  HStack,
  TagLabel,
  Text,
  Flex,
  FormControl,
  Select,
  Center,
  Spinner,
} from "@chakra-ui/react";
import CustomFormLabel from "../custom-styled-components/CustomFormLabel";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror-rego/mode"; // You can now use mode: 'rego'
// and/or
import "codemirror/addon/comment/comment";
import "codemirror/addon/edit/matchbrackets";
import "codemirror-rego/key-map";
import * as yup from "yup";
import { useControl } from "../../context/control-context";
import CustomButton from "../custom-styled-components/CustomButton";
import {
  createControl,
  publishControl,
  updateControl as updateControlRequest,
  getControl as getControlRequest,
} from "@defense-station/api-service";
import {
  showErrorToast,
  showServerError,
  showSuccessToast,
} from "../../services/toast-service";
import { useAuth } from "../../context/auth";
import CustomTag from "../CustomTag";

const VALIDATION_SCHEMA = yup.object().shape({
  control_data: yup.string().required().max(1000),
});

const INIT_FORM_DATA = {
  control_data: "",
  opa_query: "",
  tags: [],
};

export default function ControlForm() {
  const { user } = useAuth();
  const {
    isControlModalOpen,
    onControlModalOpen,
    onControlModalClose,
    selectedControl,
    setSelectedControl,
    addControl,
    updateControl,
    filter,
  } = useControl();
  const [isChanged, setChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showVersion, setShowVersion] = useState(false);
  const [control, setControl] = useState();
  const versions = Array.from(
    {
      length: selectedControl?.latest_version
        ? selectedControl?.latest_version
        : 0,
    },
    (_, index) =>
      (selectedControl?.latest_version ? selectedControl?.latest_version : 0) -
      index
  ).sort((a, b) => b - a);
  const getControl = async (version) => {
    setLoading(true);
    getControlRequest({
      account_id: user?.account_id,
      username: user?.username,
      control_id: selectedControl?.control_id,
      version: parseInt(version),
      draft: false,
    })
      .then((res) => {
        setControl(res?.data?.policy_manager_v1_GetControl?.control);
        setShowVersion(true);
      })
      .catch(showServerError)
      .finally(() => {
        setLoading(false);
      });
  };
  const onSubmit = async (values, { setFieldValue }) => {
    try {
      const requestPayload = {
        account_id: user?.account_id,
        username: user?.username,
        control_id: values?.control_id,
        control_data: values.control_data,
        opa_query: values.opa_query,
        tags: values?.tags?.map((op) => op.value),
      };
      let res = null;
      if (!selectedControl?.control_id || selectedControl?.ds_managed) {
        res = await createControl(requestPayload);
        let version = selectedControl?.ds_managed?selectedControl?.version:selectedControl?.latest_version;
        if (values?.publish) {
          const publishedResponse = await publishControl({
            account_id: user?.account_id,
            username: user?.username,
            control_id:
              res?.data?.policy_manager_v1_CreateControlDraft?.control
                ?.control_id,
          });
          version = publishedResponse?.data?.policy_manager_v1_PublishControl?.latest_version
        }
        setFieldValue(
          "control_id",
          res?.data?.policy_manager_v1_CreateControlDraft?.control?.control_id
        );
        if(!selectedControl?.ds_managed) {
          addControl({...res?.data?.policy_manager_v1_CreateControlDraft?.control, [selectedControl?.ds_managed?"version":"latest_version"]: version});
          values?.publish
            ? showSuccessToast(`Control draft has been published.`)
            : showSuccessToast(`Control draft has been created.`);
        }
        else{
          showSuccessToast(`Control has been copied to your workspace.`)
        }
      } else {
        res = await updateControlRequest(requestPayload);
        let version = null;
        if (values?.publish) {
          const publishedResponse = await publishControl({
            account_id: user?.account_id,
            username: user?.username,
            control_id: values?.control_id,
          });
          version = publishedResponse?.data?.policy_manager_v1_PublishControl?.latest_version
        }
        console.log(version?{...requestPayload, latest_version: version}:{...requestPayload})
        updateControl(version?{...requestPayload, latest_version: version}:{...requestPayload});
        values?.publish
          ? showSuccessToast(`A new version of control has been published.`)
          : showSuccessToast(`Control draft has been updated.`);
      }
      setChanged(false);
      setSelectedControl();
      onControlModalClose();
    } catch (e) {
      console.log(e);
      showErrorToast(e?.message);
    }
  };

  const onVersionChange = (e) => {
    if (e.target.value != "draft") {
      getControl(e.target.value);
    } else {
      setShowVersion(false);
    }
  };
  return (
    <Drawer
      onClose={onControlModalClose}
      isOpen={isControlModalOpen}
      size={"lg"}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <HStack>
            <Text fontWeight={"bold"} fontSize={"lg"}>
              {selectedControl ? "Update Control" : "Create Control"}
            </Text>
            {filter?.ds_managed && (
              <CustomTag colorScheme={"brand"}>Defense Station</CustomTag>
            )}
          </HStack>
        </DrawerHeader>
        <DrawerBody>
          {!filter?.ds_managed && selectedControl?.control_id && (
            <FormControl mb="25px" flexDir={"column"}>
              <CustomFormLabel mr="0">Version:</CustomFormLabel>
              <Select
                borderRadius={"16px"}
                isDisabled={isLoading}
                size="sm"
                onChange={onVersionChange}
              >
                <option value={"draft"}>{"Draft"}</option>
                {versions?.map((version) => (
                  <option key={version} value={version}>
                    {"V" + version}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          {isLoading ? (
            <Center w="full" p="4">
              <Spinner />
            </Center>
          ) : (
            <Formik
              enableReinitialize
              initialValues={selectedControl ? selectedControl : INIT_FORM_DATA}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={VALIDATION_SCHEMA}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                handleChange,
                isSubmitting,
                setFieldValue,
                submitForm,
              }) => (
                <Form>
                  <TextField
                    name="control_data"
                    label="Statement"
                    isDisabled={filter?.ds_managed || showVersion}
                    value={
                      showVersion ? control?.control_data : values.control_data
                    }
                    onChange={(e) => {
                      if (!isChanged) setChanged(true);
                      handleChange(e);
                    }}
                    error={errors.control_data}
                  />
                  <Box>
                    <CustomFormLabel>OPA Query</CustomFormLabel>
                    <Box borderColor={"secondaryGray.200"} borderWidth={"1px"}>
                      <CodeMirror
                        value={
                          showVersion ? control?.opa_query : values.opa_query
                        }
                        options={{
                          mode: "rego",
                          theme: "material",
                          lineNumbers: true,
                          readOnly: filter?.ds_managed || showVersion,
                        }}
                        onBeforeChange={(editor, data, value) => {
                          if (!isChanged) setChanged(true);
                          setFieldValue("opa_query", value);
                        }}
                        onChange={(editor, data, value) => {}}
                      />
                    </Box>
                  </Box>
                  {/* <Box mt="25px">
                  <CustomFormLabel>Tags</CustomFormLabel>
                  <CreatableSelect
                    size={{ base: "md", xl: "sm", "2xl": "md" }}
                    chakraStyles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderRadius: "16px",
                      }),
                    }}
                    isMulti
                    colorScheme="brand"
                    defaultValue={values.tags}
                    onChange={(options) => setFieldValue("tags", options)}
                  />
                </Box> */}
                  {isChanged && !filter?.ds_managed && !showVersion && (
                    <CustomButton
                      mt="25px"
                      w="full"
                      variant="outline"
                      colorScheme="brand"
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      {selectedControl ? "Save" : "Create Draft"}
                    </CustomButton>
                  )}
                  {isChanged && !filter?.ds_managed && !showVersion && (
                    <CustomButton
                      mt="10px"
                      w="full"
                      colorScheme="brand"
                      onClick={() => {
                        setFieldValue("publish", true);
                        submitForm();
                      }}
                      isLoading={isSubmitting}
                    >
                      {selectedControl
                        ? "Publish New Version"
                        : "Create & Publish"}
                    </CustomButton>
                  )}
                  {selectedControl?.ds_managed && (
                    <CustomButton
                      mt="25px"
                      w="full"
                      colorScheme="green"
                      type="submit"
                      size="sm"
                      isLoading={isSubmitting}
                    >
                      Copy to your workspace
                    </CustomButton>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
