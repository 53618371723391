import React from "react";
import {
  Route,
  Outlet,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import "./assets/css/App.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-toastify/dist/ReactToastify.css";
import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "./context/auth";
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
import theme from "./theme/theme";
const Configure = React.lazy(() => import("./pages/Configure"));
import { PoliciesProvider } from "./context/policies";
const NormalLayout = React.lazy(() => import("./layouts/NormalLayout"));
const Policies = React.lazy(() => import("./pages/Policies"));
import { QuestionnaireProvider } from "./context/questionnaire-context";
const Policy = React.lazy(() => import("./pages/Policy"));
import { ToastContainer } from "react-toastify";
const MainLayout = React.lazy(() => import("./layouts/MainLayout"));
const Role = React.lazy(() => import("./pages/Role"));
const Scopes = React.lazy(() => import("./pages/Scopes"));
const Scope = React.lazy(() => import("./pages/Scope"));
const Statements = React.lazy(() => import("./pages/Statements"));
const Statement = React.lazy(() => import("./pages/Statement"));
const GraphViewer = React.lazy(() => import("./pages/GraphViewer"));
const Settings = React.lazy(() => import("./pages/Settings"));
const EmployeeDirectory = React.lazy(() => import("./pages/EmployeeDirectory"));
const MyDocuments = React.lazy(() => import("./pages/MyDocuments"));
const EmployeeView = React.lazy(() => import("./pages/EmployeeView"));
const GlobalDocViewer = React.lazy(() => import("./pages/GlobalDocViewer"));
const Templates = React.lazy(() => import("./pages/Templates"));
const Template = React.lazy(() => import("./pages/Template"));
import ControlProvider from "./context/control-context";
import ControlForm from "./components/controls/ControlForm";
const Landing = React.lazy(() => import("./pages/Landing"));
const Optin = React.lazy(() => import("./pages/OptIn"));
const DocViewer = React.lazy(() => import("./pages/DocViewer"));
import LoadingScreen from "./components/LoadingScreen";
import { TemplateProvider } from "./context/template-context";
import PersonnelProvider from "./context/personnel-context.js";
import RoleProvider from "./context/role-context.js";
import { MermaidProvider } from "./context/mermaid-context.js";
import MermaidViewer from "./components/blocknotes/MermaidViewer.jsx";
const ConnectorsSettings = React.lazy(() =>
  import("./pages/ConnectorsSettings")
);
const NotFound = React.lazy(() => import("./pages/NotFound.jsx"));
if (process.env.ENV !== "dev") console.log = () => {};
console.error = () => {};
console.warn = () => {};

export default function Root(props) {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/"
          element={
            <MainLayout>
              <Outlet />
            </MainLayout>
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="test" element={<EmployeeView />} />
          {/* <Route path="policies" element={<Policies />} /> */}
          <Route path="documents/mydocuments" element={<MyDocuments />} />
          <Route path="documents" element={<Policies />} />
          <Route path="documents/edit/:document_id" element={<Policy />} />
          <Route path="documents/create" element={<Policy />} />
          <Route path="roles" element={<GraphViewer />} />
          <Route path="roles/edit/:role_name" element={<Role edit />} />
          <Route path="roles/create" element={<Role />} />
          <Route path="scopes" element={<Scopes />} />
          <Route path="scopes/edit/:scope_id" element={<Scope edit />} />
          <Route path="scopes/create" element={<Scope />} />
          <Route path="manage/controls" element={<Statements />} />
          <Route
            path="manage/controls/edit/:control_id/:version"
            element={<Statement edit />}
          />
          <Route path="manage/controls/create" element={<Statement />} />
          <Route path="manage/roles" element={<GraphViewer />} />
          <Route path="manage/personnels" element={<EmployeeDirectory />} />
          <Route
            path="manage"
            element={<Navigate to="/manage/personnels" replace />}
          />
          <Route path="manage/personnels/view" element={<EmployeeView />} />
          <Route path="settings/info" element={<Settings />} />
          <Route path="settings/connectors" element={<ConnectorsSettings />} />
          <Route
            path="settings"
            element={<Navigate to="/settings/info" replace />}
          />
          <Route path="templates" element={<Templates />} />
          <Route path="templates/create" element={<Template />} />
          <Route
            path="templates/edit/:template_id/:ds_managed?"
            element={<Template />}
          />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
        </Route>
        <Route
          path="configure"
          element={
            <QuestionnaireProvider>
              <NormalLayout>
                <Configure />
              </NormalLayout>
            </QuestionnaireProvider>
          }
        />
        <Route path="global/documents/:document_id" element={<DocViewer />} />
        <Route path="global" element={<GlobalDocViewer />} />
        <Route path="optin" element={<Optin />} />
        <Route path="landing" element={<Landing />} />
        <Route path="*" element={<NotFound />} />
      </>
    ),
    {
      basename: "/policy",
    }
  );
  //Test
  return (
    <>
      <ChakraProvider theme={theme}>
        <PoliciesProvider>
          <AuthProvider>
            <ControlProvider>
              <PersonnelProvider>
                <RoleProvider>
                  <MermaidProvider>
                    <TemplateProvider>
                      <React.Suspense fallback={<LoadingScreen />}>
                        <RouterProvider router={router} />
                      </React.Suspense>
                    </TemplateProvider>
                    <ControlForm />
                    <MermaidViewer />
                  </MermaidProvider>
                </RoleProvider>
              </PersonnelProvider>
            </ControlProvider>
          </AuthProvider>
        </PoliciesProvider>
        <ToastContainer />
      </ChakraProvider>
    </>
  );
}
