import { createContext, useContext, useEffect, useState } from "react";

const QuestionnaireContext = createContext({});

export const QuestionnaireProvider = ({ children }) => {
  const [questions, setQuestions] = useState();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [answers, setAnswers] = useState();
  const [isComplete, setComplete] = useState(false);
  const [connectors, setConnectors] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [enableAI, setEnableAI] = useState(false);
 
  const updateAnswer = (questionID, answer) => {
    console.log("Answered", questionID, answer)
    setAnswers(prevAnswers => {
        return (prevAnswers?{...prevAnswers, [questionID]: answer}: {[questionID]: answer})
    })
  }

  const onNext = () => {
    if(currentQuestionIndex == questions?.length - 1){
        setComplete(true)
    }
    else {
        setCurrentQuestionIndex(currentQuestionIndex + 1)
    }
}

const onBack = () => {
  if(currentQuestionIndex < 1){
    return;
  }
  setCurrentQuestionIndex(currentQuestionIndex - 1);
}


const addConnector = (plugin) => {
  const newConnectorArray = [
    ...connectors,
    {
      ...plugin,
      added: true,
    }
  ]
  setConnectors((connectorArray) => {
    return [...connectorArray, ...newConnectorArray]
  })
  // setTimeout(() => {
  //   const mappedConnectors = newConnectorArray?.map(p => {
  //     if(p.name == plugin.name){
  //       p.adding = false;
  //       p.added = true;
  //     }
  //     return p
  //   })
  //   console.log({mappedConnectors})
  //   setConnectors((connectorArray) => {
  //     return [...connectorArray, ...mappedConnectors]
  //   })
  // }, 2000)
}
    

  const value = {
    addConnector,
    connectors,
    setConnectors,
    questions,
    setQuestions,
    answers,
    setAnswers,
    updateAnswer,
    currentQuestionIndex,
    onNext,
    isComplete,
    setComplete,
    onBack,
    approvers,
    setApprovers,
    reviewers, 
    setReviewers,
    enableAI, 
    setEnableAI,
  };

  return <QuestionnaireContext.Provider value={value}>{children}</QuestionnaireContext.Provider>;
};
export const useQuestionnaire = () => useContext(QuestionnaireContext);