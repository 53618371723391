import { createContext, useContext, useEffect, useState } from "react";

const PoliciesContext = createContext({});

export const PoliciesProvider = ({ children }) => {
  // We can have our existing Auth logic here, just making sure that we have a single QueryClient.
  const [policies, setPolicies] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const addPolicy = (policy) => {
    let newPolicies = [...policies, policy];
    setPolicies(newPolicies);
  }

  const addPolicies = (addPolicies) => {
    let newPolicies = [...policies, ...addPolicies];
    setPolicies(newPolicies);
  }

  const removePolicy = (index) => {
    let newPolicies = policies.filter(p => p != index);
    setPolicies(newPolicies);
  }

  const addDocumentToSelectedList = (document) => {
    setSelectedDocuments(oldDocuments => {
      return [...oldDocuments, document]
    })
  }

  const removeDocumentFromSelectedList = (document) => {
    setSelectedDocuments(oldDocuments => {
      return oldDocuments?.filter(old => document?.document_id != old.document_id)
    })
  }

  const clearDocumentSelection = () => setSelectedDocuments([])

  // Any user details we want can go here
  const value = {
    policies,
    addPolicy,
    addPolicies,
    setPolicies,
    removePolicy,
    addDocumentToSelectedList,
    removeDocumentFromSelectedList,
    selectedDocuments,
    setSelectedDocuments,
    clearDocumentSelection
  };

  return <PoliciesContext.Provider value={value}>{children}</PoliciesContext.Provider>;
};
export const usePolicies = () => useContext(PoliciesContext);