import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./auth";
import { listControls } from "@defense-station/api-service";
import { useDisclosure } from "@chakra-ui/react";

const INITIAL_FILTER = {
  search: "",
  ds_managed: false,
};

const ControlContext = createContext();

const ControlProvider = ({ children, context }) => {
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [selectedControl, setSelectedControl] = useState();
  const {
    isOpen: isControlModalOpen,
    onOpen: onControlModalOpen,
    onClose: onControlModalClose,
  } = useDisclosure();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [initialized, setInitialized] = useState(false);
  const [isLoadingNext, setLoadingNext] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [filter, setFilter] = useState(INITIAL_FILTER);
  const [isRefreshing, setRefreshing] = useState(false);

  const fetchData = (
    state = {
      reset: false,
    }
  ) => {
    setRefreshing(true);
    let args = {
      account_id: user.account_id,
      username: user.username,
      version: null,
      draft: true,
      latest: undefined,
      page_size: pageSize,
      page_number: state.reset ? 1 : page,
    };
    if (filter) {
      if (filter?.ds_managed) {
        args["ds_managed"] = true;
      }
    }
    listControls(args)
      .then((response) => {
        const loadedControls = response?.data?.policy_manager_v1_ListControls
          ?.controls
          ? response?.data?.policy_manager_v1_ListControls?.controls
          : [];
        if (!loadedControls?.length) {
          setIsLast(true);
          return;
        }
        // setPage(response?.data?.policy_manager_v1_ListControls.last_cursor);
        let newData = [];
        if (state.reset) {
          newData = loadedControls ? loadedControls : [];
          setData(newData);
        } else {
          if (loadedControls) {
            newData = [...data, ...loadedControls];
            setData(newData);
          }
        }
        if (page == pageSize || loadedControls?.length < pageSize) {
          setIsLast(true);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setInitialized(true);
        setRefreshing(false);
        setLoadingNext(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (user && initialized) {
        resetState();
      fetchData({
        reset: true,
      });
    }
  }, [user, filter]);

  useEffect(() => {
      if(initialized) {
          setLoadingNext(true)
          fetchData();
      }
  }, [page, pageSize])

  const addControl = (control) => {
    console.log({ control, data });
    const newControls = [...data, control];
    setData(newControls);
  };

  const removeControl = (control) => {
    const newControls = data?.filter((c) => c.control_id != control.control_id);
    setData(newControls);
  };

  const updateControl = (control) => {
    const newControls = data?.map((c) => {
      if (c.control_id == control.control_id) {
        return control;
      } else {
        return c;
      }
    });
    setData(newControls);
  };

  const resetState = () => {
    setData([]);
    setIsLast(false);
    setPage(1);
  };

  const onRefresh = () => {
    resetState();
    fetchData({
      reset: true
    });
  };

  const fetchNext = () => {
    if (initialized) {
      // setLoadingNext(true);
      setPage(page + 1)
    }
  };

  const updateFilter = (f) => {
    setFilter(f);
  };

  const resetFilter = () => setFilter(INITIAL_FILTER);

  const value = {
    isControlModalOpen,
    onControlModalOpen,
    onControlModalClose,
    data,
    setData,
    addControl,
    removeControl,
    updateControl,
    selectedControl,
    setSelectedControl,
    isLoadingNext,
    initialized,
    setInitialized,
    isLast,
    setIsLast,
    isLoading,
    pageSize,
    setPageSize,
    page,
    setPage,
    resetState,
    isRefreshing,
    onRefresh,
    fetchNext,
    fetchData,
    updateFilter,
    filter,
    resetFilter,
  };

  return (
    <ControlContext.Provider value={value}>{children}</ControlContext.Provider>
  );
};

export const useControl = () => useContext(ControlContext);

export default ControlProvider;
