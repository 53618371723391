import { createContext, useContext, useEffect, useState } from "react";
import {
  getGeneratePolicyStatus,
  generateDocuments,
} from "@defense-station/api-service";
import { showErrorToast, showSuccessToast } from "../services/toast-service";
import { useAuth } from "./auth";

const TemplateContext = createContext({});

const statusCheckerIntervals = {};

export const TemplateProvider = ({ children }) => {
  const { user } = useAuth();
  const [templateUnderProcessing, setTemplatesUnderProcessing] = useState([]);
  const [checkedTemplates, setCheckedTemplates] = useState([]);

  const getGenerationStatus = async (key, template) => {
    const res = await getGeneratePolicyStatus({
      account_id: user?.account_id,
      username: user?.username,
      redis_key: key,
    });
    const status = res?.data?.policy_manager_v1_GetGeneratePolicyStatus?.status;
    const generatedDocuments =
      res?.data?.policy_manager_v1_GetGeneratePolicyStatus?.generated_documents;
    // let found = false;
    // if(generatedDocuments?.length){
    //   templateUnderProcessing?.map(p => {
    //     if(generatedDocuments?.includes(p)){
    //       found = true;
    //       template = p;
    //     }
    //     return p
    //   })
    // }
    if (status?.includes("policies generated")) {
      clearInterval(statusCheckerIntervals[key]);
      console.log("Document generated", template);
      showSuccessToast(`Document generated for ${template}`,
      { position: "bottom-right" });
      if (template) {
        setTemplatesUnderProcessing((templates) => {
          const newTemplates = templates?.filter((t) => t != template);
          return newTemplates;
        });
        setCheckedTemplates((templates) => {
          const newTemplates = templates?.filter((t) => t != template);
          return newTemplates;
        });
      }
    }
  };

  const runStatusChecker = (key, template) => {
    statusCheckerIntervals[key] = setInterval(() => {
      console.log("Running status checker");
      getGenerationStatus(key, template);
    }, 2000);
  };

  const createDocument = async (template, isCustom = false) => {
    generateDocuments({
      account_id: user?.account_id,
      username: user?.username,
      document_names: [template],
      custom_template: isCustom
    })
      .then((res) => {
        setTemplatesUnderProcessing((templates) => {
          const newTemplates = [...templateUnderProcessing, template];
          return newTemplates;
        });
        runStatusChecker(
          res?.data?.policy_manager_v1_GenerateDocuments?.redis_key,
          template
        );
      })
      .catch((e) => {
        console.log(e);
        showErrorToast(e?.message);
      });
  };

  const createDocuments = async (templates, isCustom = false) => {
    for (let index = 0; index < templates.length; index++) {
      const template = templates[index];
      generateDocuments({
        account_id: user?.account_id,
        username: user?.username,
        document_names: [template],
        custom_template: isCustom
      })
        .then((res) => {
          setTemplatesUnderProcessing((templateUnderProcessing) => {
            const newTemplates = [...templateUnderProcessing, template];
            return newTemplates;
          });
          runStatusChecker(
            res?.data?.policy_manager_v1_GenerateDocuments?.redis_key,
            template
          );
        })
        .catch((e) => {
          console.log(e);
          showErrorToast(e?.message);
        });
    }
  };

  const selectTemplate = (template) => {
    setCheckedTemplates((templates) => {
      const newSelectedTempaltes = [...templates, template];
      return newSelectedTempaltes;
    });
  };

  const unselectTemplate = (template) => {
    setCheckedTemplates((templates) => {
      const newSelectedTempaltes = templates?.filter((t) => t != template);
      return newSelectedTempaltes;
    });
  };

  const value = {
    createDocument,
    templateUnderProcessing,
    selectTemplate,
    unselectTemplate,
    checkedTemplates,
    createDocuments,
  };

  return (
    <TemplateContext.Provider value={value}>
      {children}
    </TemplateContext.Provider>
  );
};
export const useTemplate = () => useContext(TemplateContext);
