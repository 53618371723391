import toast from "../components/toast";


export const showErrorToast = (description) => {
    toast({
        title: "Error",
        description: description,
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
}

export const showWarningToast = (description) => {
  toast({
    title: "Warning",
    description: description,
    status: 'warning',
    duration: 4000,
    isClosable: true,
    position: 'top',
  });
}

export const showSuccessToast = (description, options = {}) => {
  toast({
      title: "Success",
      description: description,
      status: 'success',
      duration: 4000,
      isClosable: true,
      position: 'top',
    }, options);
}


export const showServerError = (e) => {
  console.log(e)
  toast({
    title: "Error",
    description: e?.message,
    status: 'error',
    duration: 4000,
    isClosable: true,
    position: 'top',
  });
}