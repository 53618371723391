import {
  Button,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Mermaid from "./Mermaid";
import { useMermaid } from "../../context/mermaid-context";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { PiMagnifyingGlassMinus, PiMagnifyingGlassPlus } from "react-icons/pi";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { RiFullscreenExitLine, RiFullscreenFill } from "react-icons/ri";

export default function MermaidViewer() {
  const { isOpen, onOpen, onClose, mermaidCode } = useMermaid();
  const [modalSize, setModalSize] = useState("6xl")
  const [isFullScreen, setFullScreen] = useState(false);
  const onToggleFullScreenModal = () => {
    setModalSize(!isFullScreen?"full":"6xl")
    setFullScreen(!isFullScreen);
  }
  return (
    <Modal isCentered isOpen={isOpen} size={modalSize} onClose={onClose}>
      <ModalOverlay />
      <TransformWrapper>
        {(utils) => (
          <ModalContent>
            <ModalCloseButton zIndex={2} />
            <ModalBody p={0}>
              <Flex w="full" h={isFullScreen?"100vh":"50vh"} justify={"center"} align={"center"}>
                <TransformComponent contentStyle={{
                    width: "100%",
                    height: "100%"
                }} wrapperStyle={{
                    width: "100%",
                    height: "100%"
                }}>
                  <Mermaid
                    forcedDimensions={{
                      height: "100%",
                      width: "100%",
                    }}
                    name={"Test"}
                    chart={mermaidCode}
                  />
                </TransformComponent>
              </Flex>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                position={"absolute"}
                top="20px"
                left="20px"
                px="5px"
                py="10px"
                borderWidth={"1px"}
                borderRadius={"full"}
                borderColor={"brand.500"}
              >
                <Tooltip shouldWrapChildren label="Zoom In" fontSize="md">
                  <Icon
                    cursor={"pointer"}
                    onClick={() => utils.zoomIn()}
                    as={PiMagnifyingGlassPlus}
                    color={"brand.500"}
                    boxSize={6}
                  />
                </Tooltip>
                <Tooltip shouldWrapChildren label="Zoom Out" fontSize="md">
                  <Icon
                    cursor={"pointer"}
                    onClick={() => utils.zoomOut()}
                    as={PiMagnifyingGlassMinus}
                    color={"brand.500"}
                    boxSize={6}
                  />
                </Tooltip>
                <Tooltip shouldWrapChildren label="Fit" fontSize="md">
                  <Icon
                    cursor={"pointer"}
                    onClick={() => utils.resetTransform()}
                    as={AiOutlineFullscreenExit}
                    color={"brand.500"}
                    boxSize={6}
                  />
                </Tooltip>
                <Tooltip shouldWrapChildren label="Fit" fontSize="md">
                  <Icon
                    cursor={"pointer"}
                    onClick={onToggleFullScreenModal}
                    as={isFullScreen?RiFullscreenExitLine:RiFullscreenFill}
                    color={"brand.500"}
                    boxSize={6}
                  />
                </Tooltip>
              </Stack>
            </ModalBody>
          </ModalContent>
        )}
      </TransformWrapper>
    </Modal>
  );
}
